.region-directlinks .hiag-shortcuts-view {
  --shortcut-container-height: 120px;
  --shortcut-transform-percent: 50%;
  @media screen and (max-width: 968px) {
    --shortcut-container-height: 300px;
    --shortcut-transform-percent: 20%;
  }
  @media screen and (max-width: 402px) {
    --shortcut-container-height: calc(100% - 200px);
    --shortcut-transform-percent: 0%;
  }

  transform: translateY(calc(-1 * var(--shortcut-transform-percent)));
  position: relative;
  justify-content: center;
  height: var(--shortcut-container-height);

  & > * {
    position: relative;
    z-index: 10;
  }

  .hiag-shortcut-button {
    border-width: 0 !important;
  }

  &::after {
    content: "";
    position: absolute;
    height: calc(var(--shortcut-container-height) + 100px);
    width: 70%;
    margin: 0% 15%;
    top: 0;
    background-color: var(--white);
    box-shadow: var(--global-shadow-small);
    border-radius: 28px;
  }

  .view-content {
    justify-content: space-evenly;

    .views-row {
      a {
        box-shadow: var(--global-shadow-small);
        position: relative;
        border-radius: 4px;
        transition: background-color 0.4s;

        .shortcut-title-wrapper .shortcut-title {
          color: var(--black);
        }

        @media screen and (max-width: 720px) {
          padding: 2.2rem;
          img {
            height: 100px;
            width: 100px;
          }
        }

        @media screen and (max-width: 402px) {
          transform: translateY(-50px);
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 50%;
          background-color: var(--primary-color-lighten-4);
          bottom: -1px;
          left: 0;
          border-radius: 0px 0px 4px 4px;
          transition: height 0.4s;
        }

        & > * {
          z-index: 10;
          position: relative;
        }
      }

      a:hover {
        background-color: var(--secondary-color);

        &::after {
          background-color: var(--secondary-color-lighten-3);
          height: 65%;
        }
      }

      @media screen and (min-width: 968px) {
        &:first-child a {
          border-radius: 28px 4px 4px 28px;

          &::after {
            border-radius: 0px 0px 4px 28px;
          }
        }

        &:last-child a {
          border-radius: 4px 28px 28px 4px;

          &::after {
            border-radius: 0px 0px 28px 4px;
          }
        }
      }

      a.hiag-external-link::before {
        content: none;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .region-sub-content .tabs .tabs-title {
    a {
      color: var(--black);

      &:hover {
        color: var(--black);
      }
    }

    &.is-active a {
      background: transparent;
    }
  }
}

#block-hiag-base-theme-paddingblock {
  opacity: 0 !important;
}

@media screen and (min-width: 1024px) {
  #hiag-layout-content.hiag-layout-content--center {
    animation-name: centerMain;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }

  @keyframes centerMain {
    0% {
      transform: translateX(0%);
      opacity: 0;
    }
    100% {
      transform: translateX(-17.5%);
      opacity: 1;
    }
  }
}

@media print, screen and (min-width: 64em) {
  .node--view-mode-full .page-sub-content:before {
    box-shadow: 0px 0px 12px rgba(85, 85, 85, 0.6);
  }
}

.node--type-tabs-site
  .hiag-tabs-wrapper
  ul.hiag-tabs
  .tabs-title:not(.is-active)
  a:hover,
.node--type-tabs-site
  .hiag-tabs-wrapper
  ul.hiag-tabs
  .tabs-title:not(.is-active)
  a:focus,
.node--type-tabs-site
  .hiag-tabs-wrapper
  .view-content
  > .tabs
  .tabs-title:not(.is-active)
  a:hover,
.node--type-tabs-site
  .hiag-tabs-wrapper
  .view-content
  > .tabs
  .tabs-title:not(.is-active)
  a:focus {
  color: var(--white);
}

#block-hauptnavigation ul.submenu li:hover > a {
  color: var(--white);
}

#block-hiag-base-theme-primary-local-tasks {
  position: absolute;
  z-index: 11;
  width: 100%;
  margin: var(--global-margin);
  padding: var(--global-padding);
  transform: translateY(-144px);
  box-shadow: var(--global-shadow-small);
  border-radius: 4px;

  background-color: #fefefe33;
  backdrop-filter: blur(10px);

  * {
    color: var(--black);
    border-color: var(--black);
  }
}

.views-field-edit-node .field-content a:after {
  content: none !important;
}

footer.hiag-footer-container {
  box-shadow: var(--global-shadow-small);
  padding-top: 3rem;
}

@media screen and (min-width: 1024px) {
  .region-breadcrumb #block-hiag-base-theme-breadcrumbs {
    margin-left: 25%;
    padding-left: 1.05rem;
    position: relative;
    z-index: 100;
  }
}

@media screen and (max-width: 1024px) {
  .hiag-main-wrapper {
    background-color: var(--white);
  }

  @media screen and (min-width: 720px) {
    footer.hiag-footer-container {
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 720px) {
  footer.hiag-footer-container {
    background-color: var(--primary-color-lighten-4);

    [class*="tab"] {
      background-color: transparent !important;
    }
  }
}

.region-right-off-canvas .hiag-logo-container {
  height: 120px;
}

.niederhienigen-resize {
  max-width: 75%;
}
